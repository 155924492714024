var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relation_box"},[_c('div',{staticClass:"submit_form"},[_c('div',[_c('p',[_c('span',[_vm._v("客户名称")]),_c('span',[_vm._v(_vm._s(_vm.order.companyName))])]),_c('p',[_c('span',[_vm._v("销售订单编号")]),_c('span',[_vm._v(_vm._s(_vm.order.orderNo))])])]),_c('div',[_c('p',[_c('span',{staticStyle:{"width":"95px"}},[_vm._v("所属部门")]),_c('span',[_c('el-tooltip',{attrs:{"placement":"top"}},[_vm._v("· "),_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.order.departmentAdminDOList),function(item){return _c('p',{key:item.id,staticStyle:{"line-height":"2"}},[_vm._v(" "+_vm._s(item.departmentName)+" ")])}),0),_c('em',{staticStyle:{"font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.returnName(_vm.order))+" ")])])],1)]),_c('p',[_c('span',[_vm._v("所属人")]),_c('span',[_vm._v(_vm._s(_vm.order.adminName))])])])]),(_vm.order.productCategory != 12)?_c('div',{staticClass:"table_box"},[_c('el-table',{attrs:{"data":_vm.tableData,"height":"100%","header-cell-style":{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }}},[_c('el-table-column',{attrs:{"prop":"updateTime","label":"交付时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                            color:
                                scope.row.contractStatus == 3 ? 'red' : '',
                        })},[_vm._v(" "+_vm._s(scope.row.placeOrderTime ? scope.row.placeOrderTime.split(' ')[0] : '- -')+" ")])]}}],null,false,3349361565)}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"产品名称","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                            color:
                                scope.row.contractStatus == 3 ? 'red' : '',
                        })},[_vm._v(" "+_vm._s(_vm.$tableDataHandle.productCategory( scope.row.productCategory ))+" ")])]}}],null,false,2246913500)}),_c('el-table-column',{attrs:{"label":"类型","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                            color:
                                scope.row.contractStatus == 3 ? 'red' : '',
                        })},[_c('span',[_vm._v(_vm._s(scope.row.productCategory == 2 ? _vm.$MailStatus.planTypeAli( scope.row.planType ) : _vm.$MailStatus.planType(scope.row.planType)))])])]}}],null,false,3257930304)}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"账号数","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                            color:
                                scope.row.contractStatus == 3 ? 'red' : '',
                        })},[(
                                scope.row.productCategory == 1 ||
                                scope.row.productCategory == 2 ||
                                scope.row.productCategory == 4 ||
                                scope.row.productCategory == 10
                            )?_c('span',[_vm._v(" "+_vm._s(scope.row.number || '0')+" 用户 ")]):_c('span',[_vm._v(" - - ")])])]}}],null,false,1236085583)}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"年限","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                            color:
                                scope.row.contractStatus == 3 ? 'red' : '',
                        })},[_c('span',[_vm._v(" "+_vm._s(_vm.returnTrem(scope.row)))])])]}}],null,false,2161627016)}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"交付订单编号"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{style:({
                            color:
                                scope.row.contractStatus == 3 ? 'red' : '',
                        })},[_vm._v(" "+_vm._s(scope.row.orderPlanNo)+" ")])]}}],null,false,3350586927)}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1)],1):_c('div',{staticClass:"table_box"},[_c('el-table',{ref:"multipleTable",attrs:{"data":_vm.tableData,"height":"100%","header-cell-style":{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }}},[_c('el-table-column',{attrs:{"label":"客户名称","show-overflow-tooltip":"","width":"192"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.customerName || '- -'))])]}}])}),_c('el-table-column',{attrs:{"label":"域名","show-overflow-tooltip":"","width":"182"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.domain || '- -'))])]}}])}),_c('el-table-column',{attrs:{"label":"类型","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.planType == 1 ? '新购' : scope.row.planType == 2 ? '续费' : '- -'))])]}}])}),_c('el-table-column',{attrs:{"label":"年限","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.returnTrem(scope.row)))])]}}])}),_c('el-table-column',{attrs:{"label":"注册时间","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.openTime ? scope.row.openTime.slice(0, 10) : '- -' || '- -'))])]}}])}),_c('el-table-column',{attrs:{"label":"到期时间","show-overflow-tooltip":"","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.expireTime ? scope.row.expireTime.slice(0, 10) : '- -'))])]}}])}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1)],1),_c('div',{staticClass:"footer_btn"},[_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":_vm.onClose}},[_vm._v("关 闭")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }