<template>
    <div class="orderchannel_box">
        <el-form label-width="75px" label-position="left" size="small">
            <el-form-item label="客户" required>
                <el-select
                    v-model="form.customerId"
                    size="small"
                    filterable
                    style="width: 240px"
                    placeholder="请选择搜索或直接输入"
                    remote
                    :remote-method="getCustomerList"
                >
                    <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.companyName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品类别" required>
                <el-cascader
                    v-model="form.productCategory"
                    clearable
                    :options="targetList"
                    :show-all-levels="false"
                    placeholder="请选择产品类别"
                    style="width: 240px"
                    :props="{
                        value: 'id',
                        label: 'productName',
                        children: 'children',
                        emitPath: false,
                    }"
                    @change="
                        (form.version = ''),
                            (form.productType = ''),
                            changeType(form.productCategory)
                    "
                >
                </el-cascader>
            </el-form-item>
            <div class="columns" v-if="form.isTrue">
                <div
                    v-if="fieldList.length > 0"
                    v-for="i in fieldList"
                    style="margin-right: 10px"
                >
                    <span v-if="!i.isDis">
                        <el-form-item :label="i.attributeName" required>
                            <el-select
                                v-if="
                                    i.productAttributeValueBOList &&
                                    i.productAttributeValueBOList.length > 0
                                "
                                v-model="form[i.attributeColumnName]"
                                placeholder="请选择"
                                style="width: 240px"
                                @change="getType(i.attributeColumnName, form)"
                            >
                                <el-option
                                    v-for="(
                                        item, j
                                    ) in i.productAttributeValueBOList"
                                    :key="j"
                                    :label="item.attributeValueName"
                                    :value="item.attributeValue"
                                >
                                </el-option>
                            </el-select>
                            <span v-else>
                                <el-input
                                    v-model="form[i.attributeColumnName]"
                                    placeholder="请输入"
                                    style="width: 240px"
                                ></el-input>
                            </span>
                        </el-form-item>
                    </span>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import { customerList } from '@/api/report/report.js';
import {
    productList,
    productAttributeRelationList,
} from '@/api/product/product.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                productCategory: '',
                version: '',
                productType: '',
                receivableAmount: '',
                isTrue: false,
                customerId: '',
            },
            targetList: [],
            fieldList: [],
            options: [],
            data: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData() {
            this.form = {
                productCategory: '',
                version: '',
                productType: '',
                receivableAmount: '',
                isTrue: false,
                customerId: '',
            };
            this.fieldList = [];
            sessionStorage.setItem('customerlist', 1);
            this.getCustomerList();
            this.productList();
        },
        getCustomerList(name) {
            let data = {
                param: {},
                pageNum: 1,
                pageSize: 10,
            };
            if (name) {
                data.param.name = name;
            }
            customerList(data).then((res) => {
                this.options = res.data.list;
                this.optionsFilter = res.data.list;
            });
        },
        changeType(id) {
            let data = {
                param: {
                    productId: id,
                },
            };
            this.form.isTrue = false;
            productAttributeRelationList(data).then((res) => {
                if (res.code == 200) {
                    this.fieldList = res.data.list.sort(
                        this.compare('reorder')
                    );
                    console.log(this.fieldList);
                    if (res.data.list.length > 0) {
                        this.form.isTrue = true;
                    } else {
                        this.form.isTrue = false;
                    }
                }
            });
        },
        getType(type, row) {
            console.log(type, row);
            if (
                type == 'productType' &&
                (row.productCategory == 1 ||
                    row.productCategory == 2 ||
                    row.productCategory == 4)
            ) {
                for (let j in this.fieldList) {
                    this.fieldList[j].isDis = false;
                    if (
                        row.productType == 3 &&
                        this.fieldList[j].attributeColumnName == 'term'
                    ) {
                        // this.fieldList.splice(j, 1);
                        this.fieldList[j].isDis = true;
                    }
                }
            }
        },
        compare(p) {
            return function (m, n) {
                var a = m[p];
                var b = n[p];
                return a - b; //升序
            };
        },
        // 产品列表
        productList() {
            let data = {
                param: {
                    status: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            productList(data).then((res) => {
                let dataArr = this.$Tree.treeDataTranslateAudit(
                    res.data.list.sort(this.compare('reorder')),
                    'id',
                    'parentId'
                );
                this.targetList = dataArr;
                for (let i in this.targetList) {
                    if (this.targetList[i].parentId != 0) {
                        this.targetList.splice(i, 1);
                    }
                }
            });
        },
        // getTotal() {
        //     console.log(123);
        //     let price = this.data.reduce(
        //         (sum, e) => sum + Number(e.receivableAmount || 0),
        //         0
        //     );
        //     this.total = price;
        // },
        onInput(val, name) {
            if (name == 'term') {
                if (Number(val) > 20) {
                    return this.$message.error('年限不允许大于20年');
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.orderchannel_box {
    width: 100%;
    overflow: hidden;
    .el-form {
        width: 60%;
        margin: 20px auto 0;
    }
}
</style>
