var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderchannel_box"},[_c('el-form',{attrs:{"label-width":"75px","label-position":"left","size":"small"}},[_c('el-form-item',{attrs:{"label":"客户","required":""}},[_c('el-select',{staticStyle:{"width":"240px"},attrs:{"size":"small","filterable":"","placeholder":"请选择搜索或直接输入","remote":"","remote-method":_vm.getCustomerList},model:{value:(_vm.form.customerId),callback:function ($$v) {_vm.$set(_vm.form, "customerId", $$v)},expression:"form.customerId"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.companyName,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"产品类别","required":""}},[_c('el-cascader',{staticStyle:{"width":"240px"},attrs:{"clearable":"","options":_vm.targetList,"show-all-levels":false,"placeholder":"请选择产品类别","props":{
                    value: 'id',
                    label: 'productName',
                    children: 'children',
                    emitPath: false,
                }},on:{"change":function($event){(_vm.form.version = ''),
                        (_vm.form.productType = ''),
                        _vm.changeType(_vm.form.productCategory)}},model:{value:(_vm.form.productCategory),callback:function ($$v) {_vm.$set(_vm.form, "productCategory", $$v)},expression:"form.productCategory"}})],1),(_vm.form.isTrue)?_c('div',{staticClass:"columns"},_vm._l((_vm.fieldList),function(i){return (_vm.fieldList.length > 0)?_c('div',{staticStyle:{"margin-right":"10px"}},[(!i.isDis)?_c('span',[_c('el-form-item',{attrs:{"label":i.attributeName,"required":""}},[(
                                i.productAttributeValueBOList &&
                                i.productAttributeValueBOList.length > 0
                            )?_c('el-select',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"},on:{"change":function($event){return _vm.getType(i.attributeColumnName, _vm.form)}},model:{value:(_vm.form[i.attributeColumnName]),callback:function ($$v) {_vm.$set(_vm.form, i.attributeColumnName, $$v)},expression:"form[i.attributeColumnName]"}},_vm._l((i.productAttributeValueBOList),function(item,j){return _c('el-option',{key:j,attrs:{"label":item.attributeValueName,"value":item.attributeValue}})}),1):_c('span',[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"},model:{value:(_vm.form[i.attributeColumnName]),callback:function ($$v) {_vm.$set(_vm.form, i.attributeColumnName, $$v)},expression:"form[i.attributeColumnName]"}})],1)],1)],1):_vm._e()]):_vm._e()}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }